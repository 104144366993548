// Images file for 'autogo2.insurancespecialists.com'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import NextArrow from '../../../../src/images/blue-arrow-black.png';
import NextArrowWhite from '../../../../src/images/next-arrow-white.png';
// footer logos
import AaaInsurance from './assets/insLogos/aaaInsurance.png';
import AmericanFamily from './assets/insLogos/american-family.png';
import Esurance from './assets/insLogos/esurance.png';
import Farmers from './assets/insLogos/farmers.png';
import LibertyMutual from './assets/insLogos/liberty-mutual.png';
import Mercury from './assets/insLogos/mercury.png';
import Nationwide from './assets/insLogos/nationwide.png';
import Progressive from './assets/insLogos/progressive.png';
import Asphalt from './assets/asphalt.jpg';
import Clay from './assets/slate.jpg';
import Metal from './assets/metal.jpg';
import Plastic from './assets/tile.jpg';
import Wood from './assets/woodShingle.jpg';

export default {
  Asphalt,
  Clay,
  Metal,
  Plastic,
  Wood,
  NextArrowWhite,
  Favicon,
  Dropdown,
  NextArrow,
  AaaInsurance,
  AmericanFamily,
  Esurance,
  Farmers,
  LibertyMutual,
  Mercury,
  Nationwide,
  Progressive,
};
